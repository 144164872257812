import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import RouterComponent from './shared/services/utils/RouterComponent';
// import { ReactComponent as LoadingIcons } from './assets/images/loading.svg';
import AppStore from './shared/services/utils/AppStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
import { CompanyRequestPayload, TokenInfo, User } from './shared/Interfaces';
import qonsuntivoApiService from './shared/services/api/qonsuntivo-api.service';
import { apiClient } from './shared/services/utils/utils.service';
import { RedirectPortalQilab } from './configs/environments';

export default function App() {
  const cookies = useMemo(() => new Cookies(), []);
  const qilabAuth = 'QiLabAuth';
  const [loading, setLoading] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [loggedUser, setLoggedUser] = useState<User | undefined>();

  const decryptToken = (token: string): TokenInfo => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  const getCookie = useCallback(async () => {
    let result: string = '';
    const token = await Promise.resolve(cookies.get(qilabAuth));
    if (token) {
      result = token;
      await AppStore.setToken('Bearer ' + token);
    }
  }, [cookies]);

  const login = useCallback(async () => {
    // console.log("LoginData")
    // const request: Object = {
    //   username: "meron",
    //   password: "M:O:N:826*i.c.a."
    // }
    // await qonsuntivoApiService.loginData(request);
    getCookie();
    if (!cookies.get(qilabAuth))
      return (window.location.href = RedirectPortalQilab);
    apiClient.setHeader('AuthQuid', 'Bearer ' + cookies.get(qilabAuth));
    setLoading(true);
    const response = await qonsuntivoApiService.loginBC();
    if (response) {
      localStorage.setItem('token', response.payload.token);
      apiClient.setHeader('AuthQuid', 'Bearer ' + response.payload.token);
      const userFromToken: TokenInfo = decryptToken(response.payload.token);
      const loggedUser: User = {
        fullName: '',
        firstName: '',
        lastName: '',
        username: userFromToken.user.toLowerCase(),
        mail: '',
        companies: userFromToken.companies,
        company: userFromToken.company,
        wuser: userFromToken.wresource,
        commessaContenitore: null
      };

      AppStore.loggedUser = loggedUser;
      localStorage.setItem('wuser', userFromToken.wresource);
      localStorage.setItem('company', userFromToken.company);
      localStorage.setItem('username', userFromToken.user.toLocaleLowerCase());
      localStorage.setItem(
        'companies',
        JSON.stringify(userFromToken.companies)
      );

      //Retrieve info from Token to call service to company
      const payload: CompanyRequestPayload = {
        company: userFromToken.company,
        username: userFromToken.user.toLocaleLowerCase()
      };
      const queryParams: Object = {
        company: userFromToken.company,
        wuser: userFromToken.wresource
      };
      await qonsuntivoApiService.selectCompany(payload, queryParams);
      setLoggedUser(loggedUser);
    }
    setLoading(false);
    setLoginSuccess(true);
  }, [cookies, getCookie]);

  useEffect(() => {
    const init = async () => {
      await AppStore.init();
    };
    init();
    login();
  }, [login]);

  return (
    <BrowserRouter>
      <ToastContainer />
      {!loading && loginSuccess && <RouterComponent userInfo={loggedUser!} />}
      {/* {loading && <LoadingIcons />}  */}
    </BrowserRouter>
  );
}
