import { create, ApiResponse } from "apisauce";
import { showError, showReport } from "../utils";
import Cookies from 'universal-cookie';

/**
 * Will throw an exception if server returned an error
 * @param res
 */
export const responseErrorCheck = <T = any>(res: ApiResponse<T, any>) => {

  const cookies = new Cookies();
  const url = "https://portal.dev.qilab.it/";
  
  if (res?.status === 0 || res?.problem === "UNKNOWN_ERROR") {
    throw new Error("Server error");
  }

  if (res && (res.status === 200 || res.status === 204)) {
    return res.data as T;
  }

  if (res.status === 401 && res.config?.url === "/login") {
    throw new Error("Credential error");
  }

  if (res.status === 401) {
    if(cookies.get('company')) {
      cookies.remove('company')
      window.location.href = url;
      showReport("Sessione scaduta")
    }
    throw new Error("Not authorized");
  }

  if (res.status === 403) {
    throw new Error("You don't have permission to access this resource");
  }

  if (res.status === 404) {
    throw new Error(res?.data?.message ?? "404 Error");
  }

  if (res.status === 500) {
    //showError("Errore! Consuntivo non inserito")
  }

  if (res?.status && res?.status >= 400) {
    const errorHasCode =
      res.data?.errorCode !== undefined || res.data?.messageCode !== undefined;
    throw new Error(
      `${res.data?.errorCode ?? res.data?.messageCode ?? ""}${
        errorHasCode ? ": " : ""
      }${res.data?.message}` ?? "Generic Error"
    );
  }

  if (res.data && res.data.error?.message) {
    throw new Error("Error");
  }

  if (!res || !res.data) {
    throw new Error("Malformed Response from server");
  }

  if (res.problem) {
    throw new Error("Error");
  }

  return res.data;
};

const api = create({
  //baseURL: 'https://extranet.quidinfo.it',
  //baseURL: "/",
  baseURL: "http://localhost:8080",
  headers: {
    accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// api.axiosInstance.interceptors.response.use(
//     (response: any) => response,
//     (error: any) => {
//       if (error.request.responseURL.includes('/login')) {
//         return Promise.reject(error)
//       }
//       if (error.response.status === 401) {
//       } else {
//         return Promise.reject(error)
//       }
//     }
//   )

export { api };
