export const ApiUrl = {
    VERIFY: "/verify",
    LOGIN: "/auth/login",
    COMPANY: "/functions/selectCompany",
    SMARTWORKING: "/smartworking/updateSmartworkingByDate",
    DAILY_COMMISSIONS: "/timesheet/getDailyCommissions",
    TIMESHEET_BY_WEEK: "/timesheet/getListTimesheetByWeek",
    TIMESHEET_BY_MONTH: "/timesheet/getTimesheetByMonth",
    HEAD_TIMESHEET_BY_WEEK: "/timesheet/getIdHeadTimesheetByWeek",
    INSERT_ACTIVITY_REPORT: "/timesheet/insertTimesheet",
    DELETE_ACTIVITY_REPORT: "/timesheet/deleteTimesheet",
};