import "./NewSelect.css";


export default function NewSelect ({
  placeholder,
  isMonth,
  isCompany,
  defaultValue,
  // width,
  options,
  onSelected
}:{
  placeholder?: string,
  isMonth: boolean,
  isCompany: boolean,
  defaultValue?: number,
  // width?: string,
  options: string[],
  onSelected: (n: string | number) => void
}) {
  
  const updateValue = ( event: React.ChangeEvent<HTMLSelectElement> ) => {
    // console.log(event.target.value) ;
    isCompany ? onSelected (event.target.value) : onSelected (parseInt(event.target.value, 10))
  };
  
  return (
    <div>
      {isMonth && <div>
        <select className="form-select mx-1" value={defaultValue} onChange={updateValue} >
          {/* <div className="form-select-group"> */}
            <option className="form-select-option"selected disabled hidden>{placeholder}</option>
            {options.map((option, id)=>(
            // <option key={id+1} value={id+1}>{option}</option>
            <option className="form-select-option" key={id+1} value={id+1}>{option}</option>
            
            ))}
          {/* </div> */}
        
        </select>
      </div>}
      {!isMonth && <div>
        <select className="form-select mx-1" value={defaultValue} onChange={updateValue} >
          {/* <div className="form-select-group"> */}
            <option className="form-select-option" selected disabled hidden>{placeholder}</option>

            {options.map((option)=>(
            <option className="form-select-option" key={option} value={option}>{option}</option>  
            ))}
          {/* </div> */}
        </select>
      </div>}
    </div>
    
  )
}