const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "";
const PORTAL_URL = process.env.REACT_APP_PORTAL_URL
  ? process.env.REACT_APP_PORTAL_URL
  : "";

export const enviroment = {
  // baseUrl: '/'
  // baseUrl: 'https://qonsuntivo-dev.qilab.it/'
  // baseUrl: "http://localhost:8080"
  baseUrl: BASE_URL,
};

export const DefaultCompany = "QI-Lab";
// export const RedirectPortalQilab = 'https://portal.qilab.it/';
export const RedirectPortalQilab = PORTAL_URL;
