import { PlusCircleOutlined } from '@ant-design/icons';
import { ICellRendererParams } from 'ag-grid-community';
import './PlusButtonCellRenderer.css'

const PlusButtonCellRenderer = (props: ICellRendererParams) => {  
  
  // const searchRowIndex = (currentRowIndex: number) => {
  //   const grid = props.api
  //   const rowNode = props.node
  //   const commessa: Column | null = props.columnApi.getColumn("CodCommessa")
  //   const fase: Column | null = props.columnApi.getColumn("Fase")
  //   var rowIndex: number = currentRowIndex+1
  //   while (
  //     grid.getValue(commessa!, rowNode) === grid.getValue(commessa!, grid.getDisplayedRowAtIndex(rowIndex)!) &&
  //     grid.getValue(fase!, rowNode) === grid.getValue(fase!, grid.getDisplayedRowAtIndex(rowIndex)!)
  //   ) {
  //     rowIndex++
  //   }
    
  //   console.log(grid.getRowNode(rowIndex.toString())!)
  //   return rowIndex
  // }

  const onClick = () => {
    var jsonObj = JSON.parse('{}')
    jsonObj["CodCliente"] = props.data.CodCliente
    jsonObj["CodCommessa"] = props.data.CodCommessa
    jsonObj["CodFase"] = props.data.CodFase
    jsonObj["Commessa"] = props.data.Commessa
    jsonObj["Fase"] = props.data.Fase
    jsonObj["NroFase"] = props.data.NroFase
    jsonObj["PlusButton"] = true
    jsonObj["Scadenza"] = props.data.Scadenza
    //searchRowIndex(props.rowIndex)
    props.api.applyTransaction({addIndex: props.rowIndex+1, add: [jsonObj]})
    
    props.node.setDataValue(props.column!, false)
    props.api.redrawRows()
  }

  return (
    <div onClick={onClick} className='plusButtonCell m-auto d-flex justify-content-center'>
        <PlusCircleOutlined className='m-auto plusIcon'/>
    </div>
  );
};

export default PlusButtonCellRenderer