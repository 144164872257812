import { toast, ToastOptions } from "react-toastify";

//import { default as AppStore } from "./AppStore"

export const showError = (message: string, options?: ToastOptions) => {
  return toast.error(message, {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

export const showReport = (message: string, options?: ToastOptions) => {
  return toast.info(message, {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};
