import { useEffect } from "react";

export default function Redirect({
    url
}: {
    url: string
}) {
   
   useEffect(() => {
      window.location.href = url;
   }, [url]);

   return <div className="d-flex justify-content-center align-items-center mx-auto mw-100 container-fluid min-vh-100"><h1>Redirect...</h1></div>;
};