import { AgGridReact } from 'ag-grid-react';
import { useRef, useState } from 'react';
import './Grid.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { showError, showReport } from "../../../../shared/services/utils";
import { ColSpanParams, EditableCallbackParams, ICellRendererParams } from 'ag-grid-community';
import { CommissionReport, PhaseReport, TimesheetActivityReport } from '../../Interfaces';
import { checkIfDateIsPastExpiration, convertMonthFromNumber, formatDate, setWeekDay } from '../../../../shared/services/utils/DateHelper';
import SmartworkingRenderer from '../smartworkingRenderer/SmartworkingRenderer';
import PlusButtonCellRenderer from '../plusButtonCellRenderer/PlusButtonCellRenderer';
import BlankCellRenderer from '../BlankCellRenderer';
import qonsuntivoApiService from '../../../../shared/services/api/qonsuntivo-api.service';
import { ActivityReportRequestPayload } from '../../../../shared/Interfaces';
import { DefaultCompany } from '../../../../configs/environments';

export default function Grid({
    timePeriod,
    data,
    pinnedRowData,
    pinnedBottomData,
    ribaltamento,
    excludeWeekend
}: {
    timePeriod?: any,
    data: any[],
    pinnedRowData: any[],
    pinnedBottomData: any[],
    ribaltamento: boolean,
    excludeWeekend: boolean
}) {

  const getCurrentCompany = () => {
    return localStorage.getItem("company");
  }
  const getCurrentUserName = () => {
    return localStorage.getItem("username");
  }
  const getToken = () => {
    return localStorage.getItem("token");
  }
  const getCompanies = () => {
    return JSON.parse(localStorage.getItem("companies")!);
  }
  const getWUser = () => {
    return localStorage.getItem("wuser");
  }
  
  const gridApiRef = useRef<any>(null);
  const [selectedMonth] = useState(Number(timePeriod.month));
  const [selectedYear] = useState(Number(timePeriod.year));
  const weekendIncluded: string[] = ["Dom","Lun","Mar","Mer","Gio","Ven","Sab"];
  const weekendExcluded: string[] = ["Lun","Mar","Mer","Gio","Ven"];
  const specialSeparator: string = "%%";


  function isTotalRow(params: ColSpanParams) {
      return params.data.section === "totals" || params.data.smartworking === "smartworking";
  }

  function isCellEditable(params: EditableCallbackParams) {
    if (params.data.section === "totals" || params.data.smartworking === "smartworking") return true;
  
    const grid = params.api;
    const currentColumn = params.column;
    const currentRow = params.node;
    const prevRowIndex = currentRow.rowIndex! - 1;
    if (currentRow.rowIndex! > 0) {
      const prevRow = grid.getDisplayedRowAtIndex(prevRowIndex);
      if (
        grid.getValue(currentColumn.getParent().getDisplayedLeafColumns()[0], prevRow!) === undefined &&
        //grid.getValue(currentColumn.parent.children[0], prevRow!) === undefined &&
        currentRow.data.Commessa === prevRow!.data.Commessa && currentRow.data.Fase === prevRow!.data.Fase
      ) return true;
    }
    return false;
  }

  const cellStyle = (params: any) => {
    if (params.data.section === "totals") {
      const color = totalDailyHoursCellColor(params.value)
      return {
        backgroundColor: color,
        fontSize: 'larger'
      };
    }
    if (params.data.smartworking === "smartworking") {
      return {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 'larger',
        border: 'none'
      };
    }
    if ((params.data.Commessa).includes("COMMESSA CONTENITORE") && (params.data.Commessa).includes(selectedYear)) {
      return {
        backgroundColor: '#FCF4E0',
        border: 'none'
      };
    }
    if ((params.data.Commessa).includes("ACCORDO QUADRO QUIN") && (params.data.Commessa).includes(selectedYear)) {
      return {
        backgroundColor: '#FCF4E0',
        border: 'none'
      };
    }
    if (!checkIfDateIsPastExpiration(params.column.parent.groupId, params.node.data.Scadenza)) {
      return {
        backgroundColor: '#ECF1F4',
        border: 'none'
      };
    }
    return;
  }

  const totalDailyHoursCellColor = (val: number) => {
      if (val === 0) {
        return '#F86A6A';
      } else if (val >= 8) {
        return '#9ACB62';
      } else if (val >= 1 && val < 8){
        return '#F8C283';
      }
  }

  const setColumnsToDisplay = (year: number, month: number): any[] => {
    if (excludeWeekend) return buildColumnsToDisplay(year, month, weekendExcluded);
    return buildColumnsToDisplay(year, month, weekendIncluded);
  }

  /**Builds columns to display in grid based on the time period chosen*/
  function buildColumnsToDisplay(year: number, month: number, acceptedDays: string[]){
      const days = new Date(year, month, 0).getDate();
      const columnsToDisplay = [];
      columnsToDisplay.push(
          {
              headerName: "Commesse",
              children: [
                  {
                    field: "CodCliente", 
                    editable: false, 
                    width: 90, 
                    pinned: 'left', 
                    hide: false, 
                    lockPosition: true,
                    cellRendererSelector: (params: ICellRendererParams) => {
                      const blankCell = {
                        component: BlankCellRenderer,
                      }
                      const grid = params.api
                      const rowIndex = params.rowIndex
                      const currentRow = params.node
                      const prevRow = grid.getDisplayedRowAtIndex(rowIndex-1)
                      if (
                        rowIndex !== 0 && 
                        prevRow?.data.CodCommessa === currentRow.data.CodCommessa &&
                        prevRow?.data.CodCliente === currentRow.data.CodCliente
                      ) {
                        return blankCell;
                      }
                      return undefined;
                    },
                    columnGroupShow: 'open', 
                    suppressNavigable: true, 
                    suppressCellFocus: true
                  },
                  {
                    field: "CodCommessa", 
                    editable: false, 
                    width: 100, 
                    pinned: 'left', 
                    hide: false, 
                    lockPosition: true, 
                    cellRendererSelector: (params: ICellRendererParams) => {
                      const blankCell = {
                        component: BlankCellRenderer,
                      }
                      const grid = params.api;
                      const rowIndex = params.rowIndex;
                      const currentRow = params.node;
                      const prevRow = grid.getDisplayedRowAtIndex(rowIndex-1);
                      if (rowIndex !== 0 && prevRow?.data.CodCommessa === currentRow.data.CodCommessa) {
                        return blankCell;
                      }
                      return undefined;
                    },
                    columnGroupShow: 'open', 
                    tooltipField: "CodCommessa",
                    suppressNavigable: true, 
                    suppressCellFocus: true
                  },
                  {
                      field: "Commessa", 
                      editable: false, 
                      width: 200, 
                      pinned: 'left', 
                      sortable: true, 
                      lockPosition: true,
                      cellRendererSelector: (params: ICellRendererParams) => {
                        const blankCell = {
                          component: BlankCellRenderer,
                        }
                        const grid = params.api
                        const rowIndex = params.rowIndex
                        const currentRow = params.node
                        const prevRow = grid.getDisplayedRowAtIndex(rowIndex-1)
                        if (rowIndex !== 0 && prevRow?.data.Commessa === currentRow.data.Commessa) {
                          return blankCell;
                        }
                        return undefined;
                      },
                      filter: 'agTextColumnFilter',
                      filterParams: {
                          filterOptions: ['contains','startsWith'],
                          suppressAndOrCondition: true,
                          caseSensitive: false,
                          defaultOption: 'contains',
                      },
                      tooltipField: "Commessa",
                      suppressNavigable: true,
                      suppressCellFocus: true
                  },
                  {field: "CodFase", editable: false, width: 80, pinned: 'left', hide: true, lockPosition: true, columnGroupShow: 'open', suppressNavigable: true, suppressCellFocus: true},
                  {field: "NroFase", editable: false, width: 80, pinned: 'left', hide: true, lockPosition: true, columnGroupShow: 'open', suppressNavigable: true, suppressCellFocus: true},
                  {field: "Fase", editable: false, width: 150, pinned: 'left', lockPosition: true, tooltipField: "Fase", suppressNavigable: true, suppressCellFocus: true},
                  {
                    field: "PlusButton", 
                    headerName: "",
                    editable: false, 
                    width: 50, 
                    pinned: 'left', 
                    lockPosition: true, 
                    suppressNavigable: true, 
                    suppressCellFocus: true,
                    cellRendererSelector: (params: ICellRendererParams) => {
                      const plusButtonCell = {
                        component: PlusButtonCellRenderer,
                      }
                      const blankCell = {
                        component: BlankCellRenderer,
                      }
                      // if ((params.data.Commessa).includes("ACCORDO QUADRO QUIN") && (params.data.Commessa).includes(selectedYear)){
                      //   return blankCell;
                      // }
                      if (params.data.PlusButton === true && (new Date(year, month, 0) < new Date(params.node.data.Scadenza))) {
                        return plusButtonCell;
                      }
                      return blankCell;
                    },
                  },
                  {field: "Scadenza", editable: false, width: 90, pinned: 'left', hide: false, lockPosition: true, columnGroupShow: 'open', suppressNavigable: true, suppressCellFocus: true}
              ]
          }
      )

      /**
       * Per ogni giorno sono presenti le colonne:
       *  - Ore
       *  - Note
       *  - Bottone cancellazione
       *  - Nro riga nel timesheet
      */
      for ( let i=0 ; i<days; i++){
          var currentDate = new Date(year,month-1,i+1);
          if (acceptedDays.includes(setWeekDay(currentDate))) {
              columnsToDisplay.push(
                  {
                      headerName: setWeekDay(currentDate)+" "+(i+1)+" "+convertMonthFromNumber(month)+" "+year,
                      groupId: formatDate(currentDate, true),
                      //headerTooltip: editable ? 'Apri per modificare consuntivazione' : '',
                      children: [
                      {   
                          headerName: "Ore",
                          field: "ore"+formatDate(currentDate, true),
                          colSpan: (params: ColSpanParams) => {
                              if (isTotalRow(params)) {
                              return 2;
                              } else {
                                return 1;
                              }
                          },
                          tooltipField: "Ore",
                          lockPosition: true,
                          editable: function (params: any) {
                              const cellDate = params.column.parent.groupId
                              const phaseExpiration = params.node.data.Scadenza
                              
                              return !isCellEditable(params) && 
                                      !(params.data.Commessa).includes("COMMESSA CONTENITORE") &&
                                      checkIfDateIsPastExpiration(cellDate, phaseExpiration) && 
                                      !((params.data.Commessa).includes("ACCORDO QUADRO QUIN") && (params.data.Commessa).includes(selectedYear));
                          }, 
                          width: 60,
                          cellEditor: 'agTextCellEditor',
                          cellRendererSelector: (params: any) => {
                            const smartWorkingDetails = {
                              component: SmartworkingRenderer,
                            }
                            if (params.data) {
                              if (params.data.smartworking === "smartworking") return smartWorkingDetails;
                            }
                            return undefined;
                          },
                          valueParser: (params: any) => {
                              const acceptedValues: Number[] = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5,13,13.5,14,14.5,15];
                              if(isNaN(Number(params.newValue)) || !acceptedValues.some(accValue => Number(params.newValue) === accValue)){
                                  if((Number(params.newValue) === 0 || params.newValue === "") && params.api.getValue(params.column.parent.children[0], params.node) !== undefined) {
                                      
                                      const currentRow = params.node;
                                      const currentColumn = params.column;
                                      const grid = params.api;

                                      const riga = grid.getValue(currentColumn.parent.children[2], currentRow);

                                      if (riga !== undefined) {
                                        deleteSingleActivityReport(params);
                                      }
                                  }
                                  if(params.api.getValue(params.column.parent.children[1], params.node) !== undefined) {
                                      return Number(params.oldValue);
                                  }
                                  return undefined;
                              }
                              if(Number(params.newValue) !== Number(params.oldValue) && params.api.getValue(params.column.parent.children[1], params.node) !== undefined) {
                                  return Number(params.oldValue);
                              }
                              return Number(params.newValue);
                          },
                          cellStyle: cellStyle
                      },
                      { 
                          headerName: "Note",
                          field: "note"+formatDate(currentDate, true),
                          lockPosition: true,
                          editable: function (params: any) {
                              return (params.api.getValue(params.column.parent.children[0], params.node) !== undefined &&
                                      params.api.getValue(params.column.parent.children[0], params.node) > 0 &&
                                      //params.api.getValue(params.column.parent.children[0], params.node) <= 8 && 
                                      params.api.getValue(params.column.parent.children[1], params.node) === undefined);
                          },
                          valueParser: (params: any) => {
                              if (params.newValue === undefined || params.newValue.trim().length === 0){
                                  if(params.api.getValue(params.column.parent.children[0], params.node) !== undefined) {
                                      params.node.setDataValue(params.column.parent.children[0], undefined)
                                      return undefined;
                                  }
                              }
                              insertSingleActivityReport(params, params.newValue);
                              return params.newValue;
                          } ,
                          width: 150,
                          tooltipField: "note"+formatDate(currentDate, false),
                          cellEditor: 'agTextCellEditor',
                          cellEditorPopup: true,
                          suppressNavigable: function (params: any) {
                              return (params.api.getValue(params.column.parent.children[0], params.node) === undefined ||
                                      params.api.getValue(params.column.parent.children[0], params.node) === 0 ||
                                      isNaN(Number(params.api.getValue(params.column.parent.children[0], params.node))));
                          },
                          cellStyle: cellStyle
                      },
                      {   
                          headerName: "Riga",
                          field: "nroRiga"+formatDate(currentDate, true),
                          lockPosition: true,
                          editable: false,
                          width: 80,
                          columnGroupShow: 'open'
                      }
                      ]
                  }
              ) 
          }
          
      }
      return columnsToDisplay
  }

  const onGridReady = (params: any) => {
      gridApiRef.current = params.api;
      gridApiRef.current.setColumnDefs(setColumnsToDisplay(selectedYear, selectedMonth));
  }

  const deleteSingleActivityReport = async (currentTimesheetInfo: any) => {
    const currentRow = currentTimesheetInfo.node;
    const currentColumn = currentTimesheetInfo.column;
    const grid = currentTimesheetInfo.api;
    const totalHoursRow = grid.pinnedRowModel.pinnedTopRows[0];

    const timesheet: TimesheetActivityReport = {
        giorno: currentColumn.parent.groupId,
        note: grid.getValue(currentColumn.parent.children[1], currentRow),
        nr_riga_foglio_presenza: grid.getValue(currentColumn.parent.children[2], currentRow),
        operazione: "C",
        valore: 0,
    }

    const fase: PhaseReport = {
        codice: currentRow.data.CodFase,
        nr_fase: currentRow.data.NroFase,
        timesheet: [timesheet],
    }

    const commessa: CommissionReport = {
        codice_cliente: currentRow.data.CodCliente,
        codice_commessa: currentRow.data.CodCommessa,
        foglio_presenza: '',
        fase: [fase]
    }

    let commesse: CommissionReport[] = []
    commesse.push(commessa);

    const payload: ActivityReportRequestPayload = {
      commesse: commesse
    }

    const queryParams: Object = {
      company: getCurrentCompany(),
      wuser: getWUser(),
      ribaltamento: ribaltamento
    }

    const response = await qonsuntivoApiService.deleteActivityReport(payload, queryParams);
    if (response) {
      if(response.payload.dettagli_foglio_presenza[0].esito !== "") {
        showError(response.payload.dettagli_foglio_presenza[0].esito);
      } else {
        if(getCurrentCompany() !== DefaultCompany && ribaltamento){
          showReport('Doppia consuntivazione eliminata con successo');
        }else {
          showReport('Consuntivo eliminato con successo');
        }
        
        const oldTotalHoursValue = grid.getValue(currentColumn.parent.children[0], totalHoursRow);
        const hoursToRemove = grid.getValue(currentColumn.parent.children[0], currentRow);
        totalHoursRow.setDataValue(currentColumn.parent.children[0], oldTotalHoursValue - hoursToRemove);
        currentRow.setDataValue(currentColumn.parent.children[0], undefined);
        currentRow.setDataValue(currentColumn.parent.children[1], undefined);
        currentRow.setDataValue(currentColumn.parent.children[2], undefined);
        grid.refreshCells();
      }
    }
  }

  const insertSingleActivityReport = async (currentTimesheetInfo: any, note:string, doubleInsertExtraInfo?: number) => {
      const currentRow = currentTimesheetInfo.node;
      const currentColumn = currentTimesheetInfo.column;
      const grid = currentTimesheetInfo.api;
      const totalHoursRow = grid.pinnedRowModel.pinnedTopRows[0];
      //const smartworkingRow = grid.pinnedRowModel.pinnedBottomRows[0];
      //to continue with automatic change flag

      const noteToInsert: string = 
          doubleInsertExtraInfo === undefined ? note : grid.getValue(currentColumn.parent.children[1], currentRow) + " "+specialSeparator+doubleInsertExtraInfo;

      if (!checkIfDateIsPastExpiration(currentColumn.parent.groupId, currentRow.data.Scadenza)) {
          currentRow.setDataValue(currentColumn.parent.children[0], undefined)
          currentRow.setDataValue(currentColumn.parent.children[1], undefined)
          return showError("Fase scaduta! Inserimento del consuntivo non effettuato");
      } 
          
      let timesheet: TimesheetActivityReport = {
          giorno: currentColumn.parent.groupId,
          note: noteToInsert,
          nr_riga_foglio_presenza: null,
          operazione: "I",
          valore: grid.getValue(currentColumn.parent.children[0], currentRow),
      }

      let fase: PhaseReport = {
          codice: currentRow.data.CodFase,
          nr_fase: currentRow.data.NroFase,
          timesheet: [timesheet],
      }

      let commessa: CommissionReport = {
          codice_cliente: currentRow.data.CodCliente,
          codice_commessa: currentRow.data.CodCommessa,
          foglio_presenza: "",
          fase: [fase]
      }

      let commesse: CommissionReport[] = [];
      commesse.push(commessa);

      const payload: ActivityReportRequestPayload = {
        commesse: commesse,
      }

      const queryParams: Object = {
        company: getCurrentCompany(),
        wuser: getWUser(),
        ribaltamento: ribaltamento
      }

      const response = await qonsuntivoApiService.insertActivityReport(payload, queryParams);
      if (response) {
        if(response.payload.dettagli_foglio_presenza[0].esito !== "") {
          showError(response!.payload.dettagli_foglio_presenza[0].esito);
          //elimina consuntivo precendente se ribaltamento === true
          currentRow.setDataValue(currentColumn.parent.children[0], undefined);
          currentRow.setDataValue(currentColumn.parent.children[1], undefined);
          grid.refreshCells();
          return;
        }
        if(getCurrentCompany() !== DefaultCompany && ribaltamento) {
          currentRow.setDataValue(currentColumn.parent.children[1], note);
          showReport("Doppia consuntivazione effettuata con successo!");
        } else {
          showReport("Consuntivo effettuato con successo!");
        }
        currentRow.setDataValue(currentColumn.parent.children[2], response!.payload.dettagli_foglio_presenza[0].nr_riga_foglio_presenza);
          
        const oldTotalHoursValue = grid.getValue(currentColumn.parent.children[0], totalHoursRow);
        const hoursToAdd = grid.getValue(currentColumn.parent.children[0], currentRow);
        totalHoursRow.setDataValue(currentColumn.parent.children[0], oldTotalHoursValue+hoursToAdd);
        grid.refreshCells();
      }
  }

  // function downloadSheet() {
  //     console.log("Downloading CSV")
  //     gridApiRef.current.exportDataAsCsv();
  // }

  return (
    <>
      <AgGridReact
        rowData={data}
        columnDefs={setColumnsToDisplay(timePeriod.year, timePeriod.month)}
        onGridReady={onGridReady}
        singleClickEdit={true}
        tooltipShowDelay={0}
        tooltipHideDelay={5000}
        rowSelection={'single'}
        pinnedTopRowData={pinnedRowData}
        pinnedBottomRowData={pinnedBottomData}
        suppressRowTransform={true}
      >
      </AgGridReact>
    </>
  )
}