import { Navigate, Route, Routes } from 'react-router-dom';
import GridPage from '../../../pages/grid/landing/GridPage';
import Redirect from './Redirect';
import Cookies from 'universal-cookie';
import { User } from '../../Interfaces';
import UserPasswordCard from '../../../pages/login/components/userPasswordCard/UserPasswordCard';
import LoginPage from '../../../pages/login/landing/LoginPage';

export default function Router({ userInfo }: { userInfo: User }) {
  const cookies = new Cookies();
  const qilabAuth = 'QiLabAuth';
  const url = 'https://portal.qilab.it/';

  const checkCookies = (cookie: string): boolean => {
    if (!cookies.get(cookie)) return false;
    return true;
  };

  if (!checkCookies(qilabAuth)) {
    return (
      <Routes>
        <Route path="/" element={<Redirect url={url} />} />
        <Route path="*" element={<Redirect url={url} />} />
      </Routes>
    );
  }

  // if(!localStorage.getItem("company")) {
  //     return (
  //         <Routes>
  //             <Route path="/login" element={<LoginPage />}></Route>
  //             <Route path="/" element={<Navigate to="/login" replace />}/>
  //             <Route path="*" element={<Navigate to="/login" replace />}/>
  //         </Routes>
  //     )
  // }

  return (
    <Routes>
      <Route
        path="/consuntivazione"
        element={<GridPage userInfo={userInfo} />}
      />
      <Route path="/" element={<Navigate to="/consuntivazione" replace />} />
      <Route path="*" element={<Navigate to="/consuntivazione" replace />} />
    </Routes>
  );
}
