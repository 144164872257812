import './NewSwitch.css'

export default function NewSwitch (
  {checkedLabel,
  uncheckedLabel,
  checked,
  disabled,
  onChange} : {
    checkedLabel: string,
    uncheckedLabel: string,
    checked: boolean,
    disabled: boolean,
    onChange: () => void
  }
)  {
  return (
    <div>
      {(checked && !disabled) && <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={checked} disabled={disabled} onChange={onChange}></input>
        <label className="form-check-label pe-4" htmlFor="flexSwitchCheckDefault">{checkedLabel}</label>
      </div> }
      {(!checked && !disabled) && <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={checked} disabled={disabled} onChange={onChange}></input>
        <label className="form-check-label pe-4" htmlFor="flexSwitchCheckDefault">{uncheckedLabel}</label>
      </div> }
      {(checked && disabled) && <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={checked} disabled={disabled} onChange={onChange}></input>
        <label className="form-check-label pe-4" htmlFor="flexSwitchCheckDefault">{checkedLabel}</label>
      </div> }
      {(!checked && disabled) && <div className="form-check form-switch">
        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={checked} disabled={disabled} onChange={onChange}></input>
        <label className="form-check-label pe-4" htmlFor="flexSwitchCheckDefault">{uncheckedLabel}</label>
      </div> }

      
    </div>
  )
}