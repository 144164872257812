/** 
 * Return date with format yyyyMMdd or yyyy-MM-dd
 * 
 */
export const formatDate = (date: Date, withSeparator: boolean): string => {
    const month = (date.getMonth() < 9) ? "0"+(date.getMonth()+1) : date.getMonth()+1
    const days = (date.getDate() < 10) ? "0"+date.getDate() : date.getDate()
    const formattedDate = date.getFullYear()+"-"+month+"-"+days
    return withSeparator ? formattedDate : formattedDate.replaceAll("-", "")
}

/**
 * Return shortened name of the day
 *  
 */
export const setWeekDay = (date: Date): string => {
    const week: string[] = ["Dom","Lun","Mar","Mer","Gio","Ven","Sab"]
    return week[date.getDay()]
} 

/**
 * Return full month name given its number identifier
 */
export const convertMonthFromNumber = (monthNumber: number): string => {
    switch (monthNumber) {
        case 1:
          return 'Gennaio'
        case 2:
          return 'Febbraio'
        case 3:
          return 'Marzo'
        case 4:
          return 'Aprile'
        case 5:
          return 'Maggio'
        case 6:
          return 'Giugno'
        case 7:
          return 'Luglio'
        case 8:
          return 'Agosto'
        case 9:
          return 'Settembre'
        case 10:
          return 'Ottobre'
        case 11:
          return 'Novembre'
        case 12:
          return 'Dicembre'
        default:
          return ''
      }
}

/**
 * 
 *  
 */
export const checkIfDateIsPastExpiration = (dateToCheck: Date, expirationDate: Date): boolean => {
    const d1 = new Date(dateToCheck)
    const d2 = new Date(expirationDate)
    return d1 <= d2
}