import { ApiUrl } from '../../../configs/api-url';
import { LoginResponse } from '../../../pages/login/Interfaces';
import { apiClient, errorInterceptor } from "../../../shared/services/utils/utils.service";
import { ActivityReportRequestPayload, CompanyRequestPayload, CompanyResponse, SmartworkingRequestPayload, TimesheetCommissionsResponse, TimesheetHeaderResponse, TimesheetInsertionResponse, TimesheetListResponse, UpdateSmartworkingByDateResponse } from '../../Interfaces';


const loginData = async (payload: Object): Promise<LoginResponse> => {
    const response = await apiClient.post<LoginResponse>(ApiUrl.LOGIN, {...payload});
    return errorInterceptor(response);
}

const loginBC = async (): Promise<LoginResponse> => {
    const response = await apiClient.post<LoginResponse>(ApiUrl.LOGIN);
    return errorInterceptor(response);
}

//Request body: company, username
//Query params: company, wuser
const selectCompany = async (payload: CompanyRequestPayload, queryParams: Object): Promise<CompanyResponse> => {
    const response = await apiClient.post<CompanyResponse>(ApiUrl.COMPANY, { ...payload }, { params: {...queryParams}});
    return errorInterceptor(response);
}

//Query params: date, company wuser
const getDailyCommissions = async (queryParams: Object): Promise<TimesheetCommissionsResponse> => {
    const response = await apiClient.get<TimesheetCommissionsResponse>(ApiUrl.DAILY_COMMISSIONS, { ...queryParams } );
    return errorInterceptor(response);
}

//Query params: year, month, company, wuser
const getListTimesheetByMonth = async (queryParams: Object): Promise<TimesheetListResponse> => {
    const response = await apiClient.get<TimesheetListResponse>(ApiUrl.TIMESHEET_BY_MONTH, {...queryParams });
    return errorInterceptor(response);
}

//Query params: date, company wuser
const getIdHeadTimesheetByWeek = async (queryParams: Object): Promise<TimesheetHeaderResponse> => {
    const response = await apiClient.get<TimesheetHeaderResponse>(ApiUrl.HEAD_TIMESHEET_BY_WEEK, { ...queryParams });
    return errorInterceptor(response);
}

//Request body: commesse
//Query params: company, wuser
//TODO: check Commessa interface
const insertActivityReport = async (payload: ActivityReportRequestPayload, queryParams: Object): Promise<TimesheetInsertionResponse> => {
    const response = await apiClient.post<TimesheetInsertionResponse>(ApiUrl.INSERT_ACTIVITY_REPORT, { ...payload }, { params: { ...queryParams } });
    return errorInterceptor(response);
}

//Request body: commesse
//Query params: company, wuser
//TODO: check Commessa interface
const deleteActivityReport = async (payload: ActivityReportRequestPayload, queryParams: Object): Promise<TimesheetInsertionResponse> => {
    const response = await apiClient.post<TimesheetInsertionResponse>(ApiUrl.DELETE_ACTIVITY_REPORT, { ...payload }, { params: { ...queryParams } });
    return errorInterceptor(response);
}

//Request body: company, username
//Query params: company, wuser
const updateSmartWorkingByDate = async (payload: SmartworkingRequestPayload, queryParams: Object): Promise<UpdateSmartworkingByDateResponse> => {
    const response = await apiClient.put<UpdateSmartworkingByDateResponse>(ApiUrl.SMARTWORKING, { ...payload }, { params: { ...queryParams } });
    return errorInterceptor(response);
}
  
export default {
    loginData: async (payload: Object) => loginData(payload),
    loginBC: async () => loginBC(),
    selectCompany: async (payload: CompanyRequestPayload, queryParams: Object) => selectCompany(payload, queryParams),
    getDailyCommissions: async (queryParams: Object) => getDailyCommissions(queryParams),
    getListTimesheetByMonth: async (queryParams: Object) => getListTimesheetByMonth(queryParams),
    getIdHeadTimesheetByWeek: async (queryParams: Object) => getIdHeadTimesheetByWeek(queryParams),
    insertActivityReport: async (payload: ActivityReportRequestPayload, queryParams: Object ) => insertActivityReport(payload, queryParams),
    deleteActivityReport: async (payload: ActivityReportRequestPayload, queryParams: Object ) => deleteActivityReport(payload, queryParams),
    updateSmartWorkingByDate: async (payload: SmartworkingRequestPayload, queryParams: Object) => updateSmartWorkingByDate(payload, queryParams),
}