import './GridPage.css';
import 'antd/lib/switch/style/css';
import 'antd/lib/avatar/style/css';
import logoQuin from '../../../assets/logos/QUIN-logo-1.png';
import logoQilab from '../../../assets/logos/Qilab_logo_RGB.svg';
import logoQuid from '../../../assets/logos/Quid_logo_rgb.svg';
import { useEffect, useState } from 'react';
import Grid from '../components/grid/Grid';
import AppStore from '../../../shared/services/utils/AppStore';
import { ReactComponent as LoadingIcons } from '../../../assets/images/loading.svg';
import { Fase, TimesheetCommissionsResponse } from '../Interfaces';
import {
  CompanyRequestPayload,
  ExtendedCommessa,
  Timesheet as TimesheetInterface,
  TimesheetListResponse,
  TokenInfo,
  User
} from '../../../shared/Interfaces';
import {
  convertMonthFromNumber,
  formatDate
} from '../../../shared/services/utils/DateHelper';
import NewButton from '../../../shared/components/button/NewButton';
import NewSwitch from '../../../shared/components/switch/NewSwitch';
import NewSelect from '../../../shared/components/select/NewSelect';
import Cookies from 'universal-cookie';
import qonsuntivoApiService from '../../../shared/services/api/qonsuntivo-api.service';
import { RedirectPortalQilab } from '../../../configs/environments';

export default function GridPage({ userInfo }: { userInfo: User }) {
  const cookies = new Cookies();

  const user = AppStore.loggedUser;
  const [loading, setLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });
  const [noWeekendFlag, setNoWeekendFlag] = useState<boolean>(false);
  const [ribaltamento, setRibaltamento] = useState(true);
  const [commissions, setCommissions] = useState<any[]>([]);
  const [totalDailyHours, setTotalDailyHours] = useState<any[]>([]);
  const [smartworkingData, setSmartworkingData] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(timePeriod.month);
  const [selectedYear, setSelectedYear] = useState(timePeriod.year);
  const [gridkey, setGridKey] = useState(0);

  useEffect(() => {
    retrieveCommissionsAndMonthlyTimesheet(
      selectedYear.toString(),
      selectedMonth < 10 ? '0' + selectedMonth : selectedMonth.toString()
    );
  }, []);

  const refreshGrid = (): void => {
    setLoading(true);
    setTimePeriod(() => {
      return {
        month: selectedMonth,
        year: selectedYear
      };
    });
    setGridKey(Math.random() * 10000);
    retrieveCommissionsAndMonthlyTimesheet(
      selectedYear.toString(),
      selectedMonth < 10 ? '0' + selectedMonth : selectedMonth.toString()
    );
    setLoading(false);
  };

  const handleMonthChange = (value: any): void => {
    setSelectedMonth(Number(value));
  };

  const handleYearChange = (value: any): void => {
    setSelectedYear(Number(value));
  };

  async function handleCompanyChange(value: any) {
    const payload: CompanyRequestPayload = {
      company: value,
      username: getCurrentUserName()?.toString()!
    };
    const queryParams: Object = {
      company: value,
      wuser: getWUser()?.toString()
    };
    await qonsuntivoApiService.selectCompany(payload, queryParams);
    localStorage.setItem('company', value);
    AppStore.loggedUser.company = value;
    user.company = value;
    refreshGrid();
  }

  const ribaltamentoConsuntivo = (): void => {
    setRibaltamento(!ribaltamento);
  };

  const changeNoWeekendFlag = (): void => {
    setNoWeekendFlag(!noWeekendFlag);
  };

  const insertActivityIntoCommissions = (
    commesse: any[],
    codCommessa: string,
    codFase: string,
    nrFase: string,
    consuntivo: TimesheetInterface
  ): boolean => {
    var inserted: boolean = false;
    commesse.forEach(function (com) {
      if (
        com['CodCommessa'] === codCommessa &&
        com['CodFase'] === codFase &&
        com['NroFase'] === nrFase
      ) {
        const data = consuntivo.giorno; //.replaceAll("-", "")
        if (!com.hasOwnProperty('ore' + data) && !inserted) {
          com['ore' + data] = consuntivo.valore;
          com['note' + data] = consuntivo.note;
          com['nroRiga' + data] = consuntivo.nr_riga_foglio_presenza;
          inserted = true;
          return inserted;
        }
      }
    });
    return inserted;
  };

  async function retrieveCommissionsAndMonthlyTimesheet(
    year: string,
    month: string
  ) {
    setRibaltamento(getCurrentCompany() === 'QI-Lab' ? false : true);
    setLoading(true);
    if (
      !localStorage.getItem('token') ||
      localStorage.getItem('token') === undefined
    )
      return;

    const queryParams1: Object = {
      date: formatDate(new Date(), true),
      company: getCurrentCompany(),
      wuser: getWUser()
    };
    const dailyCommissions: TimesheetCommissionsResponse =
      await qonsuntivoApiService.getDailyCommissions(queryParams1);

    const queryParams2: Object = {
      year: year,
      month: month,
      company: getCurrentCompany(),
      wuser: getWUser()
    };
    const consuntivi: TimesheetListResponse =
      await qonsuntivoApiService.getListTimesheetByMonth(queryParams2);

    var tmp_commesse: any[] = [];
    dailyCommissions.payload.commesse.forEach(function (commessa) {
      let jsonObj = JSON.parse('{}');
      jsonObj['Commessa'] = commessa.descrizione;
      jsonObj['CodCommessa'] = commessa.codice;
      jsonObj['CodCliente'] = commessa.cliente.codice;
      commessa.fase.forEach(function (fase) {
        jsonObj['PlusButton'] = true;
        jsonObj['Fase'] = fase.descrizione;
        jsonObj['CodFase'] = fase.codice;
        jsonObj['NroFase'] = fase.nr_fase;
        jsonObj['Scadenza'] = formatDate(new Date(fase.data_fine), true);
        tmp_commesse.push(jsonObj);
        jsonObj = JSON.parse(JSON.stringify(jsonObj));
      });
    });

    let rowsToDisplay: any[] = [];
    tmp_commesse.forEach(function (com) {
      let originalCom = JSON.parse(JSON.stringify(com));
      let commessaFound: boolean = false;
      let faseFound: boolean = false;
      consuntivi.payload.commesse.forEach(function (
        commessa: ExtendedCommessa
      ) {
        if (com['CodCommessa'] === commessa.codice) {
          commessaFound = true;
          let keys: number[] = [];
          commessa.fase.forEach(function (fase: Fase) {
            if (
              com['CodFase'] === fase.codice &&
              com['NroFase'] === fase.nr_fase
            ) {
              faseFound = true;
              for (let i = 0; i < fase.timesheet.length; i++) {
                var inserted: boolean = false;
                const data = Number(
                  fase.timesheet[i].giorno.replaceAll('-', '')
                );
                if ((keys.length === 0 || keys.includes(data)) && !inserted) {
                  if (
                    !insertActivityIntoCommissions(
                      rowsToDisplay,
                      commessa.codice,
                      fase.codice,
                      fase.nr_fase,
                      fase.timesheet[i]
                    )
                  ) {
                    rowsToDisplay.push(JSON.parse(JSON.stringify(originalCom)));
                    inserted = insertActivityIntoCommissions(
                      rowsToDisplay,
                      commessa.codice,
                      fase.codice,
                      fase.nr_fase,
                      fase.timesheet[i]
                    );
                  }
                } else {
                  inserted = insertActivityIntoCommissions(
                    rowsToDisplay,
                    commessa.codice,
                    fase.codice,
                    fase.nr_fase,
                    fase.timesheet[i]
                  );
                }
                if (!keys.includes(data)) {
                  keys.push(data);
                }
              }
            }
          });
        }
      });
      if (!commessaFound || !faseFound) rowsToDisplay.push(com);
    });

    //Handle plus buttons
    for (let i = 0; i < rowsToDisplay.length; i++) {
      if (i > 0) {
        if (
          JSON.parse(JSON.stringify(rowsToDisplay[i]))['Commessa'] ===
            JSON.parse(JSON.stringify(rowsToDisplay[i - 1]))['Commessa'] &&
          JSON.parse(JSON.stringify(rowsToDisplay[i]))['Fase'] ===
            JSON.parse(JSON.stringify(rowsToDisplay[i - 1]))['Fase']
        ) {
          let updatedCurrentRow = JSON.parse(JSON.stringify(rowsToDisplay[i]));
          updatedCurrentRow['PlusButton'] = true;
          rowsToDisplay.splice(i, 1, updatedCurrentRow);
          let updatedPrevRow = JSON.parse(JSON.stringify(rowsToDisplay[i - 1]));
          updatedPrevRow['PlusButton'] = false;
          rowsToDisplay.splice(i - 1, 1, updatedPrevRow);
        }
      }
    }
    let jsonTotDailyHours = JSON.parse('{}');
    jsonTotDailyHours['section'] = 'totals';

    let smartworkingDays = JSON.parse('{}');
    smartworkingDays['smartworking'] = 'smartworking';

    let pinnedTopRowData: any[] = [];
    let pinnedBottomRowData: any[] = [];
    const days = new Date(Number(year), Number(month), 0).getDate();
    for (let i = 0; i < days; i++) {
      let currentDate = new Date(Number(year), Number(month) - 1, i + 1);
      jsonTotDailyHours['ore' + formatDate(currentDate, true)] =
        consuntivi.payload.totali_orari[formatDate(currentDate, true)];
      smartworkingDays['ore' + formatDate(currentDate, true)] =
        isSmartworkingDay(currentDate, consuntivi.payload.smart_working);
    }

    pinnedTopRowData.push(jsonTotDailyHours);
    pinnedBottomRowData.push(smartworkingDays);
    setTotalDailyHours(pinnedTopRowData);
    setSmartworkingData(pinnedBottomRowData);
    setCommissions(rowsToDisplay);
    setLoading(false);
  }

  const isSmartworkingDay = (date: Date, smartworkingList: any[]): boolean => {
    for (let i = 0; i < smartworkingList.length; i++) {
      if (
        date.toDateString() ===
        new Date(smartworkingList[i].giorno).toDateString()
      ) {
        return smartworkingList[i].attivoSmartworking;
      }
    }
    return false;
  };

  const months: string[] = [
    'Gennaio',
    'Febbaio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ];

  const years: string[] = ['2022', '2023'];

  const getCurrentCompany = () => {
    return userInfo.company
      ? userInfo.company
      : localStorage.getItem('company');
  };
  const getCurrentUserName = () => {
    return userInfo.username
      ? userInfo.username
      : localStorage.getItem('username');
  };
  const getToken = () => {
    return AppStore.token ? AppStore.token : localStorage.getItem('token');
  };
  const getCompanies = () => {
    return userInfo.companies
      ? userInfo.companies
      : JSON.parse(localStorage.getItem('companies')!);
  };
  const getWUser = () => {
    return userInfo.wuser ? userInfo.wuser : localStorage.getItem('wuser');
  };

  return (
    <section className="">
      <section className="container-fluid px-0">
        <header className="row g-0 pe-4 flex-row justify-content-between align-content-center custom-header">
          {getCurrentCompany() === 'QI-Lab' && (
            <img
              className="logo_Qilab col-2 ps-sm-3 ps-xxl-4 pe-xxl-2"
              src={logoQilab}
              alt={''}
            />
          )}
          {getCurrentCompany() === 'Quid Informatica S.p.A.' && (
            <img
              className="logo_Quid col-2 ps-3 ps-xxl-0 pe-4 pe-xxl-5"
              src={logoQuid}
              alt={''}
            />
          )}
          {getCurrentCompany() === 'Quin S.r.l.' && (
            <img
              className="logo_Quin col-2 ps-sm-3 ps-xxl-5 "
              src={logoQuin}
              alt={''}
            />
          )}
          <div className="col-4 d-flex align-items-center justify-content-end">
            <p className="m-0 mx-2 label">Ciao, {getCurrentUserName()!}!</p>
            <NewSelect
              placeholder={getCurrentCompany()!}
              isMonth={false}
              isCompany={true}
              options={getCompanies()}
              onSelected={handleCompanyChange}
            />
            <NewButton
              label={'Logout'}
              onClick={() => {
                AppStore.setToken('');
                cookies.remove('company');
                localStorage.clear();
                window.location.href = RedirectPortalQilab;
              }}
            />
          </div>
        </header>
        <main className="container-fluid g-0 px-5 pt-4 custom-content">
          <div className="row align-items-center justify-content-between pt-3">
            <div className="col-auto d-flex align-items-center ms-05 bg-red">
              <NewSwitch
                checkedLabel={'Weekend inclusi'}
                uncheckedLabel={'Weekend esclusi'}
                checked={!noWeekendFlag}
                disabled={false}
                onChange={changeNoWeekendFlag}
              ></NewSwitch>
              {getCurrentCompany() !== 'QI-Lab' && (
                <NewSwitch
                  checkedLabel={'Ribaltamento attivo'}
                  uncheckedLabel={'Ribaltamento disattivo'}
                  checked={ribaltamento}
                  disabled={false}
                  onChange={ribaltamentoConsuntivo}
                />
              )}
            </div>
            <div className="col-auto d-flex align-items-center justify-content-end">
              <NewSelect
                placeholder={String(convertMonthFromNumber(timePeriod.month))}
                isMonth={true}
                isCompany={false}
                options={months}
                onSelected={handleMonthChange}
              />
              <NewSelect
                placeholder={String(timePeriod.year)}
                isMonth={false}
                isCompany={false}
                options={years}
                onSelected={handleYearChange}
              />
              <NewButton label={'Aggiorna'} onClick={refreshGrid} />
            </div>
          </div>
          <div className="content-grid ag-theme-balham">
            {!loading && (
              <Grid
                key={gridkey}
                timePeriod={timePeriod}
                data={commissions}
                pinnedRowData={totalDailyHours}
                pinnedBottomData={smartworkingData}
                ribaltamento={ribaltamento}
                excludeWeekend={noWeekendFlag}
              />
            )}
            {loading && (
              <div className="bg-white w-100 h-100 d-flex align-content-center">
                <LoadingIcons className="m-auto" />
              </div>
            )}
          </div>
        </main>
      </section>
    </section>
  );
}
