import { create, ApiResponse } from 'apisauce';
import Cookies from 'universal-cookie';
import {
  enviroment,
  RedirectPortalQilab
} from './../../../configs/environments';

export const errorInterceptor = <Type>(response: ApiResponse<Type>): Type => {
  const cookies = new Cookies();
  let result: Type = response.data!;

  switch (response.status) {
    case 200:
    case 204:
      result = response.data!;
      break;

    case 401:
      cookies.remove('QiLabAuth');
      localStorage.clear();
      window.location.href = RedirectPortalQilab;
      break;

    case 403:
      break;

    case 404:
      break;

    case 405:
      break;

    case 500:
      break;
  }

  return result;
};

export const apiClient = create({
  baseURL: enviroment.baseUrl,
  headers: {
    accept: '*/*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});
