import { HomeFilled, HomeOutlined } from '@ant-design/icons';
import { ICellRendererParams } from 'ag-grid-community';
import { showError } from '../../../../shared/services/utils';
import './SmartworkingRenderer.css'
import { SmartworkingRequestPayload } from '../../../../shared/Interfaces';
import qonsuntivoApiService from '../../../../shared/services/api/qonsuntivo-api.service';

const SmartworkingRenderer =  (props: ICellRendererParams) => {
  const houseStatus = props.value === true ? 'filled' : 'empty'

  const getCurrentCompany = () => {
    return localStorage.getItem("company");
  }
  const getToken = () => {
    return localStorage.getItem("token");
  }
  const getWUser = () => {
    return localStorage.getItem("wuser");
  }

  const changeStatusIcon = async () => {
    const newSmartworkingStatus = props.value ? 'false' : 'true';
    const columnToModify: any = props.column!.getParent().getChildren()![0];

    const payload: SmartworkingRequestPayload = {
      attivo: newSmartworkingStatus === 'true' ? true : false,
      company: getCurrentCompany()!, 
      giorno: props.column!.getParent().getGroupId(),
      idRisorsa: getWUser()!
    }

    const queryParams: Object = {
      company: getCurrentCompany()!,
      wuser: getWUser()!
    }

    const response = await qonsuntivoApiService.updateSmartWorkingByDate(payload, queryParams);
    if (response) {
      if(response.metadata.messages[0].title.includes("Successo")) {
        props.node.setDataValue(columnToModify, !props.value);
      } else {
        showError('Errore! Smartworking flag non aggiornato');
      }
    }
  }
  
  return (
    <div>
        {houseStatus === 'filled' && 
            <div onClick={changeStatusIcon} className='smartworkingCell filled m-auto d-flex justify-content-center'>
                <HomeFilled className='m-auto houseIcon'/>
            </div>
        }
        {houseStatus === 'empty' && 
            <div onClick={changeStatusIcon} className='smartworkingCell m-auto d-flex justify-content-center'>
                <HomeOutlined className='m-auto houseIcon'/>
            </div>
        }
    </div>
  );
};

export default SmartworkingRenderer
