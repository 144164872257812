import { api } from '../api/api';
import { CommessaContenitore, User } from '../../Interfaces';

class AppStore {
  token?: string | null;
  loggedUser!: User;
  commessaContenitore!: CommessaContenitore;

  async init() {
    this.token = await localStorage.getItem('token');
    if (this.token) {
      api.setHeader('Authquid', this.token);
    }
    const user: User = {
      fullName: '',
      firstName: '',
      lastName: '',
      username: '',
      mail: '',
      companies: [],
      company: '',
      wuser: '',
      commessaContenitore: null
    };
    this.loggedUser = user;
  }

  async setToken(token?: string) {
    this.token = token;
    if (token) {
      localStorage.setItem('token', token);
      api.setHeader('AuthQuid', `${token}`);
    } else await localStorage.removeItem('token');
  }
}

export default new AppStore();
