import "./NewButton.css";

export default function NewButton({
  label,
  onClick
}:{
  label?: string,
  onClick: () => void
}) {
  return (
    <button type="button" className="btn d-flex flex-row align-items-center rounded-pill btn-custom mx-1" onClick={onClick}>
      {label}
    </button>
  );
}